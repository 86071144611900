import axios from "axios";

export default {
  list(params) {
    return axios.get(`marketplace_crm/vouchers`, { params });
  },
  create(payload) {
    return axios.post(`marketplace_crm/vouchers`, payload);
  },
  update(id, payload) {
    return axios.put(`marketplace_crm/vouchers/${id}`, payload);
  },
  delete(id) {
    return axios.delete(`marketplace_crm/vouchers/${id}`);
  },
  voucher(id) {
    return axios.get(`marketplace_crm/vouchers/${id}`);
  },
};
