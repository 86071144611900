<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="setShow"
      direction="rtl"
      @close="close"
    >
      <div class="el-drawer--content">
        <div class="el-drawer--content__body">
          <el-form :model="form" label-position="top" ref="form">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Voucher code"
                  prop="code"
                  :rules="validateField()"
                >
                  <el-input type="text" v-model="form.code" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Voucher type"
                  prop="value_type"
                  :rules="validateField()"
                >
                  <el-radio-group
                    v-model="form.value_type"
                    class="eden-custom--radio flex"
                  >
                    <el-radio label="percentage">Percentage discount</el-radio>
                    <el-radio label="fixed">Fixed amount discount</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" v-if="form.value_type">
              <el-col :span="24">
                <el-form-item
                  label="Voucher value"
                  prop="value"
                  :rules="validateField()"
                >
                  <el-input type="text" v-model.number="form.value" v-number>
                    <span slot="suffix">
                      <p class="my-1">
                        {{ form.value_type === "fixed" ? currency : "%" }}
                      </p>
                    </span>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Where can this voucher be applied?"
                  prop="redemption_type"
                  :rules="validateField()"
                >
                  <el-radio-group
                    v-model="form.redemption_type"
                    class="is-flex is-flex-column gap-2"
                  >
                    <div class="is-flex gap-1">
                      <div>
                        <el-radio
                          label="specific_stores"
                          :class="radioCards"
                          class="h-100 w-100"
                        >
                          <p class="text-bold">A specific store</p>
                          <span class="text-wrap font-sm">
                            Select which store(s) this voucher can be used.
                          </span>
                        </el-radio>
                      </div>
                      <div>
                        <el-radio
                          label="specific_categories"
                          :class="radioCards"
                          class="h-100 w-100"
                        >
                          <p class="text-bold">Specific Category</p>
                          <span class="text-wrap font-sm">
                            Select which categories this voucher can be used.
                          </span>
                        </el-radio>
                      </div>
                    </div>
                    <div class="is-flex w-50">
                      <el-radio label="all_stores" :class="radioCards">
                        <p class="text-bold">All stores</p>
                        <p class="text-wrap font-sm">
                          This voucher can be used on any vendor on the
                          marketplace.
                        </p>
                      </el-radio>
                    </div>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="form.redemption_type === 'specific_categories'">
              <el-col>
                <el-form-item
                  label="Category"
                  prop="specific_category_ids"
                  :rules="validateField()"
                >
                  <el-select
                    filterable
                    multiple
                    :value-key="'id'"
                    v-model="form.specific_category_ids"
                    placeholder="Select the categories that apply"
                  >
                    <el-option
                      v-for="(item, index) in businessTypes"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="form.redemption_type === 'specific_stores'">
              <el-col>
                <el-form-item label="Store" prop="vendor_store">
                  <el-select
                    class="hidden-tag"
                    filterable
                    multiple
                    :value-key="'id'"
                    v-model="vendor_store"
                    placeholder="Select a store(s)"
                  >
                    <el-option
                      v-for="(item, index) in stores"
                      :key="index"
                      :label="item.business_name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>

                <div
                  v-for="(store, index) in vendor_store"
                  :key="index"
                  class="is-flex justify-between mt-3"
                >
                  <div>
                    <p class="font-base">{{ store.business_name }}</p>
                  </div>
                  <div
                    class="is-flex gap-1 is-align-center cursor-pointer"
                    @click="removeStore(index)"
                  >
                    <i class="el-icon-close text-danger"></i>
                    <p class="text-danger">Remove</p>
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Who can use this voucher"
                  prop="customer_type"
                  :rules="validateField()"
                >
                  <el-radio-group
                    v-model="form.customer_type"
                    class="is-flex is-flex-column gap-2"
                  >
                    <div class="is-flex gap-1">
                      <div>
                        <el-radio
                          label="first_time_customers"
                          :class="radioCards"
                          class="h-100 w-100"
                        >
                          <p class="text-bold">First time users</p>
                          <span class="text-wrap font-sm">
                            Only users ordering on the Eden Marketplace for the
                            first time can use this voucher.
                          </span>
                        </el-radio>
                      </div>
                      <div>
                        <el-radio
                          label="return_customers"
                          :class="radioCards"
                          class="h-100 w-100"
                        >
                          <p class="text-bold">Returning Customers</p>
                          <span class="text-wrap font-sm">
                            Only users who have made a purchase on Eden can use
                            this voucher.
                          </span>
                        </el-radio>
                      </div>
                    </div>
                    <div class="is-flex">
                      <el-radio label="all" :class="radioCards">
                        <p class="text-bold">All Users</p>
                        <p class="text-wrap font-sm">
                          Anyone can use this voucher.
                        </p>
                      </el-radio>
                    </div>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="">
                  <el-switch
                    v-model="min_order"
                    :active-value="true"
                    active-text="This voucher has a minimum order value"
                    :inactive-value="false"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item
                  v-if="min_order"
                  label="Minimum order value"
                  prop="min_order_value"
                  :rules="validateField()"
                >
                  <el-input
                    type="text"
                    v-model.number="form.min_order_value"
                    v-number
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="">
                  <el-switch
                    v-model="voucher_limit"
                    :active-value="true"
                    active-text="This voucher has a usage limit per customer"
                    :inactive-value="false"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item
                  v-if="voucher_limit"
                  label="Usage Limit"
                  prop="usage_limit"
                  :rules="validateField()"
                >
                  <el-input
                    type="text"
                    v-model.number="form.usage_limit"
                    v-number
                  >
                    <span slot="suffix">
                      <p class="font-base text-base text-grey-quartenary my-1">
                        times
                      </p>
                    </span>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="24">
                <el-switch
                  v-model="startExpiryDate"
                  :active-value="true"
                  active-text="This voucher has a start date and expiry date"
                  :inactive-value="false"
                >
                </el-switch>
              </el-col>
            </el-row>

            <el-row type="flex" v-if="startExpiryDate">
              <el-col :span="24" class="is-flex gap-1">
                <el-form-item
                  label="Start date (optional)"
                  prop="start_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.start_date"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    placeholder="Select Start date"
                    :picker-options="{
                      disabledDate: disabledDates,
                    }"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="Expiry date (optional)"
                  prop="expiry_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.expiry_date"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    placeholder="Select expiry date"
                    :picker-options="{
                      disabledDate: disabledDates,
                    }"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer is-flex is-justify-between is-align-center">
        <el-button plain @click="close">Cancel</el-button>
        <el-button
          type="primary"
          :loading="submitting"
          :disabled="isButtonDisabled"
          @click="save"
        >
          {{ action === "edit" ? "Save changes" : "Create Voucher" }}</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import voucher from "@/requests/vouchers/voucher";
import * as actions from "@/store/action-types";
import active from "@/requests/vendors/active";

export default {
  name: "DiscountForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    voucher: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        code: "",
        value_type: "",
        value: null,
        redemption_type: "",
        customer_type: "",
        specific_category_ids: [],
        min_order_value: null,
        start_date: null,
        expiry_date: null,
        usage_limit: null,
      },
      min_order: false,
      vendor_store: [],
      startExpiryDate: false,
      expiryDate: false,
      voucher_limit: false,
      submitting: false,
      stores: [],
      radioCards:
        "is-flex gap-1 is-flex-reverse border border-grey-septenary rounded px-1 py-1",
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.action === "add" ? "Create Voucher" : "Edit Voucher";
    },
    businessTypes() {
      return this.$store.getters.business_types;
    },
    isButtonDisabled() {
      return (
        !this.form.code ||
        !this.form.value_type ||
        !this.form.value ||
        !this.form.redemption_type ||
        !this.form.customer_type
      );
    },
    location() {
      return this.$store.getters.location;
    },
    currency() {
      return this.location === "NG" ? "₦" : "KES";
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.setData();
        }
      }
    },
  },
  created() {
    this.getBusinessType();
    this.getActiveVendors();
  },
  methods: {
    selectedIds(data) {
      return data.map((item) => item.id);
    },
    getBusinessType() {
      this.loading = true;
      this.$store.dispatch(actions.GET_BUSINESS_TYPE).then(() => {
        this.loading = false;
      });
    },
    getActiveVendors() {
      this.loading = true;
      active
        .list()
        .then((res) => {
          this.stores = res.data.data;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    removeStore(index) {
      this.vendor_store.splice(index, 1);
    },
    close() {
      this.setShow = false;
      //   this.$refs.form.resetFields();
      this.form = {
        code: "",
        value_type: "",
        value: null,
        redemption_type: "",
        customer_type: "",
        min_order_value: null,
        start_date: null,
        expiry_date: null,
        usage_limit: null,
      };
      (this.min_order = false),
        (this.startExpiryDate = false),
        (this.vendor_store = []);
    },
    disabledDates(time) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return time < today.getTime();
    },
    save() {
      this.action === "edit" ? this.update() : this.add();
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.submitting = true;
        const formData = {
          ...this.form,
          specific_store_ids: this.selectedIds(this.vendor_store),
        };
        voucher
          .create(formData)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.submitting = false;
              this.$emit("success");
              this.close();
            } else {
              this.$message.error(response.data.message);
              this.submitting = false;
            }
          })
          .catch((error) => {
            this.submitting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.submitting = true;
        if (!this.form.specific_category_ids) {
          this.form.specific_category_ids = [];
        }
        const formData = {
          ...this.form,
          specific_store_ids: this.selectedIds(this.vendor_store),
        };
        voucher
          .update(this.voucher.uuid, formData)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.submitting = false;
              this.$emit("success");
              this.close();
            } else {
              this.$message.error(response.data.message);
              this.submitting = false;
            }
          })
          .catch((error) => {
            this.submitting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    setData() {
      this.form = {
        code: this.voucher.code,
        value_type: this.voucher.value_type,
        value: this.voucher.value,
        redemption_type: this.voucher.redemption_type,
        customer_type: this.voucher.customer_type,
        specific_category_ids: this.voucher.specific_category_ids,
        min_order_value: this.voucher.min_order_value,
        usage_limit: this.voucher.usage_limit,
        start_date: this.voucher.start_date
          ? this.formatDate(this.voucher.start_date, "y-m-d")
          : null,
        expiry_date: this.voucher.expiry_date
          ? this.formatDate(this.voucher.expiry_date, "y-m-d")
          : null,
      };
      this.vendor_store = this.voucher.specific_stores;
      if (this.voucher.start_date || this.voucher.expiry_date) {
        this.startExpiryDate = true;
      }
      if (this.voucher.min_order_value) {
        this.min_order = true;
      }
      if (this.voucher.usage_limit) {
        this.voucher_limit = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}

:deep .hidden-tag .el-tag {
  display: none !important;
}
</style>
