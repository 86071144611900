<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
export default {
  name: "VoucherFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        discount_category: {
          key: "discount_category",
          label: "Discount Category",
          type: "list",
          options: [
            {
              label: "Order Amount",
              value: "order_amount",
            },
            {
              label: "Delivery Fee",
              value: "delivery_fee",
            },
          ],
          value: [],
          searchable: false,
        },
        redemption_type: {
          key: "redemption_type",
          label: "Applicable Point",
          type: "list",
          options: [
            {
              label: "All Stores",
              value: "all_stores",
            },
            {
              label: "Specific Category",
              value: "specific_categories",
            },
            {
              label: "Specific Store",
              value: "specific_stores",
            },
          ],
          value: [],
          searchable: false,
        },
        customer_type: {
          key: "customer_type",
          label: "Customer Type",
          type: "list",
          options: [
            {
              label: "Return Customers",
              value: "return_customers",
            },
            {
              label: "First Time Customers",
              value: "first_time_customers",
            },
            {
              label: "All Users",
              value: "all",
            },
          ],
          value: [],
          searchable: false,
        },
        start_date: {
          key: ["min_start_date", "max_start_date"],
          label: "Start Date",
          type: "daterange",
          value: [],
        },
        expiry_date: {
          key: ["min_expiry_date", "max_expiry_date"],
          label: "Expiry Date",
          type: "daterange",
          value: [],
        },
      },
    };
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value?.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });
      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>
