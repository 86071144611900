<template>
  <section>
    <el-drawer :visible.sync="setShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="text-grey-primary">Voucher Details</span>
      </template>

      <div class="el-drawer--content" v-loading="loading">
        <div class="el-drawer--content__body">
          <!-- <pre>{{ voucher }}</pre> -->
          <div class="is-flex is-flex-column gap-2">
            <div class="is-flex is-justify-between">
              <h6>{{ voucher?.code }}</h6>

              <el-dropdown :placement="'bottom'" @command="command">
                <el-button type="plain" icon="eden-icon-more">
                  Actions
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="edit">
                    Edit voucher
                  </el-dropdown-item>
                  <el-dropdown-item command="delete" class="text-danger">
                    Delete voucher
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Voucher Type</p>

              <p class="text-bold">
                {{
                  voucher.value_type === "fixed"
                    ? "Fixed Amount Discount"
                    : "Percentage Discount"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Value</p>

              <p class="text-bold">
                {{
                  voucher.value_type === "fixed"
                    ? formatPrice(voucher.value)
                    : formatPercentage(voucher.value)
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Applicable Point</p>

              <p class="text-bold">
                {{
                  voucher.redemption_type === "specific_stores"
                    ? "Specific Store"
                    : voucher.redemption_type === "specific_categories"
                    ? "Specific Category"
                    : "All Stores"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Customer Type</p>

              <p class="text-bold">
                {{
                  voucher.customer_type === "return_customers"
                    ? "Return Customers"
                    : voucher.customer_type === "first_time_customers"
                    ? "First Time Customers"
                    : "All Users"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Minimum Order Value</p>

              <p class="text-bold">
                {{
                  voucher.min_order_value
                    ? formatPrice(voucher.min_order_value)
                    : "-"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Usage Limit</p>

              <p class="text-bold">
                {{ voucher.usage_limit || "Unlimited" }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Start Date</p>

              <p class="text-bold">
                {{ formatDate(voucher.start_date) }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Expiry Date</p>

              <p class="text-bold">
                {{ formatDate(voucher.expiry_date) }}
              </p>
            </div>
          </div>

          <div class="mt-2" v-if="voucher?.specific_stores?.length">
            <p
              @click="showContent = !showContent"
              class="is-flex is-justify-between border-bottom py-1 border-grey-secondary text-bold"
            >
              Store(s) This Voucher Applies
              <i class="el-icon-arrow-down el-icon--right"></i>
            </p>
            <div v-if="showContent">
              <p
                class="my-2"
                v-for="store in voucher.specific_stores"
                :key="store.id"
              >
                {{ store.business_name }}
              </p>
            </div>
          </div>

          <div class="mt-2" v-if="voucher?.specific_categories?.length">
            <p
              @click="showCategory = !showCategory"
              class="is-flex is-justify-between border-bottom py-1 border-grey-secondary cursor-pointer text-bold"
            >
              Categories This Voucher Applies
              <i class="el-icon-arrow-down el-icon--right"></i>
            </p>
            <div class="my-2 is-flex gap-1" v-if="showCategory">
              <div
                v-for="category in voucher.specific_categories"
                :key="category.id"
              >
                <el-tag>
                  {{ category.name }}
                </el-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="el-drawer--footer is-align-center w-100"></div> -->
    </el-drawer>
  </section>
</template>

<script>
export default {
  name: "VoucherDetail",
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    voucher: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      showContent: true,
      showCategory: true,
    };
  },

  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
    command(command) {
      switch (command) {
        case "delete":
          this.$emit("delete-voucher");
          this.closeEvent();
          break;
        case "edit":
          this.$emit("edit-voucher");
          this.closeEvent();
          break;
      }
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1.5rem;
}

.grey {
  color: #727272;
}

h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
</style>
