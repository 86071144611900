import axios from "axios";

export default {
  list(params) {
    return axios.get(`marketplace_crm/automatic_discounts`, { params });
  },
  create(payload) {
    return axios.post(`marketplace_crm/automatic_discounts`, payload);
  },
  update(id, payload) {
    return axios.put(`marketplace_crm/automatic_discounts/${id}`, payload);
  },
  delete(id) {
    return axios.delete(`marketplace_crm/automatic_discounts/${id}`);
  },
  discount(id) {
    return axios.get(`marketplace_crm/automatic_discounts/${id}`);
  },
};
