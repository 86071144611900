<template>
  <div>
    <eden-page-header :title="'Vouchers & Discounts'" />
    <template>
      <div>
        <el-tabs v-model="tab" @tab-click="updateRouteQuery">
          <el-tab-pane label="Vouchers" name="vouchers">
            <Vouchers />
          </el-tab-pane>
          <el-tab-pane
            v-if="enableTab"
            label="Automatic discounts"
            name="discounts"
          >
            <AutomaticDiscounts />
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import AutomaticDiscounts from "../../components/vouchersAndDiscounts/discounts/AutomaticDiscounts.vue";
import Vouchers from "../../components/vouchersAndDiscounts/vouchers/Vouchers.vue";

export default {
  name: "VouchersandDiscounts",
  components: { Vouchers, AutomaticDiscounts },
  data() {
    return {
      tab: "vouchers",
    };
  },
  computed: {
    enableTab() {
      return true;
    },
  },
  watch: {
    $route() {
      this.updateRouteQuery();
    },
  },
  created() {
    let { t } = this.$route.query;
    this.tab = t ? t : "vouchers";
  },
  methods: {
    updateRouteQuery(tab) {
      const { t } = this.$route.query;
      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "vouchers.index",
        query: { t: tab.name },
      });
    },
  },
};
</script>
