<template>
  <section>
    <el-drawer :visible.sync="setShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="text-grey-primary">Discount Details</span>
      </template>

      <div class="el-drawer--content" v-loading="loading">
        <div class="el-drawer--content__body">
          <!-- <pre>{{ discount }}</pre> -->
          <div class="is-flex is-flex-column gap-2">
            <div class="is-flex is-justify-between">
              <h6>
                {{
                  discount.discount_category === "order_amount"
                    ? "Order Amount"
                    : "Delivery Fee"
                }}
              </h6>

              <el-dropdown :placement="'bottom'" @command="command">
                <el-button type="plain" icon="eden-icon-more">
                  Actions
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="edit">
                    Edit discount
                  </el-dropdown-item>
                  <el-dropdown-item command="delete" class="text-danger">
                    Delete discount
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Discount Type</p>

              <p class="text-bold">
                {{
                  discount.discount_type === "fixed"
                    ? "Fixed Amount Discount"
                    : "Percentage Discount"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Value</p>

              <p class="text-bold">
                {{
                  discount.discount_type === "fixed"
                    ? formatPrice(discount.value)
                    : formatPercentage(discount.value)
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Applicable Point</p>

              <p class="text-bold">
                {{
                  discount.redemption_type === "specific_stores"
                    ? "Specific Store"
                    : discount.redemption_type === "specific_categories"
                    ? "Specific Category"
                    : "All Stores"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Customer Type</p>

              <p class="text-bold">
                {{
                  discount.customer_type === "return_customers"
                    ? "Return Customers"
                    : discount.customer_type === "first_time_customers"
                    ? "First Time Customers"
                    : "All Users"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Minimum Order Value</p>

              <p class="text-bold">
                {{
                  discount.min_order_value
                    ? formatPrice(discount.min_order_value)
                    : "-"
                }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Usage Limit</p>

              <p class="text-bold">
                {{ discount.usage_limit || "Unlimited" }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Start Date</p>

              <p class="text-bold">
                {{ formatDate(discount.start_date) }}
              </p>
            </div>
            <div class="is-flex is-justify-between">
              <p class="grey">Expiry Date</p>

              <p class="text-bold">
                {{ formatDate(discount.expiry_date) }}
              </p>
            </div>

            <div class="mt-2" v-if="discount?.specific_stores?.length">
              <p
                @click="showContent = !showContent"
                class="is-flex is-justify-between border-bottom py-1 border-grey-secondary text-bold"
              >
                Store(s) This discount Applies
                <i class="el-icon-arrow-down el-icon--right"></i>
              </p>
              <div v-if="showContent">
                <p
                  class="my-2"
                  v-for="store in discount.specific_stores"
                  :key="store.id"
                >
                  {{ store.business_name }}
                </p>
              </div>
            </div>

            <div class="mt-2" v-if="discount?.specific_categories?.length">
              <p
                @click="showCategory = !showCategory"
                class="is-flex is-justify-between border-bottom py-1 border-grey-secondary cursor-pointer text-bold"
              >
                Categories This discount Applies
                <i class="el-icon-arrow-down el-icon--right"></i>
              </p>
              <div class="my-2 is-flex gap-1" v-if="showCategory">
                <div
                  v-for="category in discount.specific_categories"
                  :key="category.id"
                >
                  <el-tag>
                    {{ category.name }}
                  </el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="el-drawer--footer is-align-center w-100"></div> -->
    </el-drawer>
  </section>
</template>

<script>
export default {
  name: "DiscountDetail",
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    discount: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      showContent: true,
      showCategory: true,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
    command(command) {
      switch (command) {
        case "delete":
          this.$emit("delete-discount");
          this.closeEvent();
          break;
        case "edit":
          this.$emit("edit-discount");
          this.closeEvent();
          break;
      }
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1.5rem;
}

h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.grey {
  color: #727272;
}
</style>
