<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <DiscountFilter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
          class="mr-2"
        />
        <el-button type="primary" @click="command({ action: 'add' })"
          >Add Discount</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        class="ml-auto"
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <template v-if="pageData?.length">
        <el-table :data="pageData" empty-text="No Discount">
          <el-table-column width="220">
            <template slot="header">
              <span>Discount Category</span>
            </template>
            <template slot-scope="scope">
              <span
                @click="
                  command({
                    action: 'detail',
                    id: scope.row.uuid,
                    index: scope.$index,
                  })
                "
                class="cursor-pointer"
              >
                {{
                  scope.row.discount_category === "order_amount"
                    ? "Order Amount"
                    : "Delivery Fee"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <span>Value</span>
            </template>
            <template slot-scope="scope">
              <span>{{
                scope.row.discount_type === "fixed"
                  ? formatPrice(scope.row.value)
                  : formatPercentage(scope.row.value)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Applicable Point</span>
              </div>
            </template>
            <template slot-scope="scope">
              {{
                scope.row.redemption_type === "specific_stores"
                  ? "Specific Store"
                  : scope.row.redemption_type === "specific_categories"
                  ? "Specific Category"
                  : "All Stores"
              }}
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Usage Limit</span>
              </div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.usage_limit || "Unlimited" }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Customer Type</span>
            </template>
            <template slot-scope="scope">
              {{
                scope.row.customer_type === "return_customers"
                  ? "Return Customers"
                  : scope.row.customer_type === "first_time_customers"
                  ? "First Time Customers"
                  : "All Users"
              }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Start Date</span>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatDate(scope.row.start_date) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Expiry Date</span>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatDate(scope.row.expiry_date) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="
              allowAccessFor([
                'superadmin',
                'admin',
                'vendor_manager',
                'operations',
              ])
            "
            min-width="60"
          >
            <template slot-scope="scope">
              <el-dropdown @command="command" class="more">
                <span class="el-dropdown-link">
                  <i class="eden-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="{
                      action: 'edit',
                      id: scope.row.uuid,
                      item: scope.row,
                      index: scope.$index,
                    }"
                    >Edit Discount</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="{
                      action: 'view',
                      id: scope.row.uuid,
                      index: scope.$index,
                    }"
                    >View Discount Details</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="{
                      action: 'delete',
                      id: scope.row.uuid,
                      index: scope.$index,
                    }"
                    class="text-danger"
                    >Delete Discount</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="pageData?.length"
          :from="pagination.from"
          :to="pagination.to"
          :total="pagination.total"
          :current-page.sync="page"
          :page-size="pagination.per_page"
        />
      </template>
      <eden-content-empty v-else :text="'No Automatic Discount'" />
    </template>
    <DiscountForm
      :action="discount.action"
      :show.sync="discount.visibility"
      :discount="singleDiscount"
      @success="getDiscounts"
    />
    <DiscountDelete
      :show.sync="showDeleteDiscount"
      :discount-id="discountId"
      @success="getDiscounts"
    />
    <DiscountDetail
      :show.sync="showDiscountDetail"
      :discount="discountDetail"
      :loading="isLoading"
      @edit-discount="command({ action: 'edit', item: discountDetail })"
      @delete-discount="command({ action: 'delete', id: discountDetail.uuid })"
    />
  </div>
</template>

<script>
import DiscountDelete from "./DiscountDelete.vue";
import DiscountDetail from "./DiscountDetail.vue";
import DiscountFilter from "./DiscountFilter.vue";
import DiscountForm from "./DiscountForm.vue";
import discount from "@/requests/vouchers/discount";

export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      pageData: [],
      discount: {
        action: "add",
        visibility: false,
        data: {},
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      discountId: "",
      showDeleteDiscount: false,
      pagination: [],
      page: 1,
      singleDiscount: {},
      discountDetail: {},
      showDiscountDetail: false,
    };
  },
  computed: {
    title() {
      return this.pageData?.length + " Automatic Discounts";
    },
  },
  created() {
    this.getDiscounts();
  },
  methods: {
    command(command) {
      this.discountId = command.id;
      this.singleDiscount = command.item;
      switch (command.action) {
        case "add":
          this.discount.action = "add";
          this.discount.visibility = true;
          break;
        case "edit":
          this.discount.action = "edit";
          this.discount.visibility = true;
          break;
        case "delete":
          this.discount.action = "delete";
          this.showDeleteDiscount = true;
          break;
        case "detail":
          this.discount.action = "detail";
          this.getDiscount();
          this.showDiscountDetail = true;
          break;
        case "view":
          this.discount.action = "view";
          this.$router.push({
            name: "discounts.details",
            params: { id: this.discountId },
          });
          break;
      }
    },
    async getDiscount() {
      try {
        this.isLoading = true;
        const response = await discount.discount(this.discountId);
        if (response.data.status) {
          this.discountDetail = response.data.data;
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getDiscounts() {
      this.loading = true;
      const params = {
        discount_category: this.filterParams.params?.discount_category,
        customer_type: this.filterParams.params?.customer_type,
        redemption_type: this.filterParams.params?.redemption_type,
        min_start_date: this.filterParams.params?.min_start_date,
        max_start_date: this.filterParams.params?.max_start_date,
        min_expiry_date: this.filterParams.params?.min_expiry_date,
        max_expiry_date: this.filterParams.params?.max_expiry_date,
      };
      await discount
        .list(params)
        .then((response) => {
          if (response.data.status) {
            this.pageData = response.data.data;
            this.pagination = response.data.meta;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      this.filterParams = {
        status: true,
        params: params,
        paramsLabel: paramsLabel,
      };
      this.page = 1;
      this.getDiscounts();
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getDiscounts();
    },
  },
  components: { DiscountForm, DiscountDelete, DiscountDetail, DiscountFilter },
};
</script>
