<template>
  <eden-confirm-dialog
    title="Delete this voucher?"
    button-type="danger"
    button-text="Yes, delete"
    :show.sync="shouldShow"
    cancel-text="No, Cancel"
    :button-status="isLoading"
    @confirm="deleteVoucher"
  >
    <p>
      Are you sure you want to delete this voucher? This action cannot be
      undone.
    </p>
  </eden-confirm-dialog>
</template>

<script>
import voucher from "@/requests/vouchers/voucher";

export default {
  name: "VoucherDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    voucherId: String,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    async deleteVoucher() {
      this.isLoading = true;
      await voucher
        .delete(this.voucherId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            this.$message.error(
              "Voucher is currently in use and cannot be deleted",
            );
            return;
          }
          this.$message.error(error.response.data.message);
        })
        .finally(() => {
          this.shouldShow = false;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
